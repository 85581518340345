<template>
  <div class="all_main_wrap">
    <div class="all_content_box">
      <div class="Up_Down_inner">
        <div class="bgDiv">
          <div class="titlediv">
          <p class="title">服务老师：</p>
          <a-select mode="multiple" placeholder="请选择服务老师" class="select" v-model="userIds">
            <a-select-option :value="item.userId" v-for="(item,index) in teacherList" :key="index"> {{item.nickName}} </a-select-option>
          </a-select>
          <a-button type="primary" class="btn" :loading="loading" @click="onSearch()" v-hasPermi="['user:list:query']">搜索</a-button>
          </div>
          <p class="hint">服务备注：<span @click="dialog()">新加备注+</span></p>
          <a-table
            class="table-template"
            :rowKey="item=>item.id"
            :columns="ServiceColumns"
            :data-source="notesList"
            :bordered="true"
            :pagination="false"
            >
            <!-- 添加人 -->
            <!-- <template slot="index" slot-scope="item">
              {{ item.teacherName }}
            </template> -->
            <!-- 备注信息 -->
            <!-- <template slot="notes" slot-scope="item">
              {{ item.notes }}
            </template> -->

        </a-table>
        </div>
        
      </div>

    </div>
   <!-- 新建/编辑 -->
   <a-modal
      title="新加备注"
      :visible="visible"
      width="360px"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="modal_box">
        <span class="tag">备注：</span>
        <a-input type="text" v-model="tUserFollowup.notes" placeholder="请输入新加备注" />
      </div>
    </a-modal>
  </div>
  
</template>

<script>
const ServiceColumns = [
  {
    title: "添加人",
    width: "150px",
    align:'center',
    dataIndex: "teacherName"

  },
  {
    title: "备注信息",
    align:'center',
    dataIndex: "notes"
  },
  {
    title: "备注时间",
    width: "200px",
    align:'center',
    dataIndex: "createTime"
  }
];
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
      teacherList: {
        type: Array,
				default: () => [],
			},
     
  },
  // 数据对象
  data () {
    return {
      ServiceColumns,//服务老师标题
      visible:false,//新增窗口显示隐藏
      tUserFollowup:{
        notes:"",//备注
        userId:this.$route.query.userId//用户id
      },
      userIds:undefined,//选中服务老师
      loading: false, // 列表加载
      notesList:[],//备注列表
    }
  },
  // 事件处理器
  methods: {
    //点击弹窗
    dialog(){
      this.visible=true;
      this.userIds=[]
      this.tUserFollowup.notes='';
    },
    //取消
    handleCancel(e) {
      this.visible = false;
    },
    // 提交
    handleOk(e) {
      if (!this.tUserFollowup.notes) {
        this.$message.warning("请输入备注");
        return;
      }
      
      this.$ajax({
        url: "/hxclass-management/user/followup/add",
        method: "POST",
        params: this.tUserFollowup,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          this.visible = false;
        } else {
          this.$message.error("操作失败");
        }
      });

    },
    // 搜索
    onSearch() {
      if(!this.userIds){
        this.$message.warning("请选择服务老师");
        return;
      }
      this.loading=true;
      this.getserviceHistoryList(Array.from(new Set(this.userIds)).toString());
    },
    // 查询用户跟进记录表列表
    getserviceHistoryList(e) {
      this.$ajax({
        url: "/hxclass-management/user/followup/list",
        params: {
          userId: this.$route.query.userId,
          serveTeacherId:e

        },
      }).then((res) => {
        this.loading=false;
        if (res.code == 200 && res.success) {
          this.notesList = res.data;
        }
      });
    
    },
   },
  // 生命周期-实例创建完成后调用
  created () {
    
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.bgDiv{
  .titlediv{
    display: flex;
    align-items: center;
    .title{
      font-size: 14px;
      font-weight: 400;
      color: #666666;
    }
    .select{
      width: 400px;
    }
  }
  .hint{
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      margin-top: 10px;
      span{
        font-size: 12px;
        text-decoration: underline;
        color: #2B6ED4;
        cursor: pointer;
      }
  }
  .table-template{
    background: white;
  }
}
.modal_box {
  display: flex;
  align-items: center;
  .tag {
    flex-shrink: 0;
  }
}
.btn {
  margin-left: 24px;
}
</style>
