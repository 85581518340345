<template>
  <div class="all_main_wrap">
    <div>收货地址</div>
    <div class="all_content_box">
      <!-- 收货地址 -->
      <div class="Up_Down_inner">
          <a-table
            class="table-template"
            :rowKey="item=>item.id"
            :columns="addressColumns"
            :data-source="addressList"
            :bordered="true"
            :pagination="false"
            >
            <template slot="index" slot-scope="item, row, i">
              <div style="text-align: center;">
                {{ i + 1 }}
              </div>
            </template>
            <template slot="phone" slot-scope="text, item">
            {{ item.phone ? $encryption('empty','phone',item.phone) : "--" }}
            </template>
            <!-- 收货地址拼接 -->
            <template slot="address" slot-scope="item">
              <span v-if="item.proviceName">{{item.proviceName}}-</span>
              <span v-if="item.cityName">{{item.cityName}}</span>
              <span v-if="$encryption('user:infomation:sensitivequery','address',item.areaName)">
              <span v-if="item.areaName">-{{item.areaName}}</span>
              <span v-if="item.address">-{{item.address}}</span>
              </span>
            </template>
        </a-table>
      </div>
    </div>
    <div>发票抬头</div>
    <div class="all_content_box">
      <!-- 发票抬头 -->
      <div class="Up_Down_inner">
          <a-table
            class="table-template"
            :rowKey="item=>item.userInvoiceId"
            :columns="invoiceColumns"
            :data-source="invoiceList"
            :bordered="true"
            :pagination="false"
            >
            <template slot="index" slot-scope="item, row, i">
              <div style="text-align: center;">
                {{ i + 1 }}
              </div>
            </template>

            <!-- 收货地址拼接 -->
            <template slot="headerType" slot-scope="item">
              <span>{{item == 1 ? '企业' : '个人'}}</span>
            </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
const addressColumns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "姓名",
    align:'center',
    dataIndex: "consignee"
  },
  {
    title: "手机号",
    align:'center',
    dataIndex: "phone",
    scopedSlots: { customRender: 'phone' }
  },
  {
    title: "地址",
    align:'center',
    scopedSlots: { customRender: 'address' }
  }
];

const invoiceColumns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "抬头类型",
    align:'center',
    dataIndex: "headerType",
    scopedSlots: { customRender: 'headerType' }
  },
  {
    title: "发票抬头",
    align:'center',
    dataIndex: "invoiceHeader"
  },
  {
    title: "电子邮箱",
    align:'center',
    dataIndex: "email"
  },
  {
    title: "税号",
    align:'center',
    dataIndex: "dutyParagraph"
  },
  {
    title: "开户银行",
    align:'center',
    dataIndex: "bank"
  },
  {
    title: "银行账号",
    align:'center',
    dataIndex: "bankAccount"
  },
  {
    title: "企业地址",
    align:'center',
    dataIndex: "companyAddress"
  },
  {
    title: "企业电话",
    align:'center',
    dataIndex: "companyPhone"
  },
];
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    addressList: { // 收货地址
				type: Array,
				default: () => [],
    },
    invoiceList: { // 发票抬头
				type: Array,
				default: () => [],
		},
  },
  // 数据对象
  data () {
    return {
      addressColumns, // 收货地址
      invoiceColumns, // 发票抬头
    }
  },
  // 事件处理器
  methods: {
  },
  // 生命周期-实例创建完成后调用
  created () {
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.Up_Down_inner{
  margin-bottom: 10px;
  .all_left_name{
    font-size: 18px;
  }
}
.all_main_wrap{
  padding: 0 16px;
}
.table-box{
  width: 800px;
}
.delItem{
  font-size: 12px;
  color: #2B6ED4;
  line-height: 14px;
  cursor: pointer;
}
</style>
