<template>
  <div class="all_main_wrap">
    <div>登录日志</div>
    <div class="all_content_box">
      <div class="searchBox">
        <a-select allowClear placeholder="请选择登录来源" style="width: 220px" v-model="loginType" @change="refresh">
          <a-select-option value="IOS">IOS</a-select-option>
          <a-select-option value="Android">Android</a-select-option>
          <a-select-option value="PCC">PCC</a-select-option>
        </a-select>
        <a-range-picker
          :placeholder="['登录开始时间','登录结束时间']"
          valueFormat="YYYY-MM-DD HH:mm:ss"
          showTime
          v-model="findtime"
          @change="refresh"
        ></a-range-picker>
      </div>
      
      <div class="Up_Down_inner">
          <a-table
            class="table-template"
            :rowKey="item=>item.logId"
            :columns="loginHistoryColumns"
            :data-source="loginHistoryList"
            :bordered="true"
            :pagination="false"
            >
            <template slot="index" slot-scope="item, row, i">
              <div style="text-align: center;">
                {{ i + 1 }}
              </div>
            </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
const loginHistoryColumns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "登录来源",
    align:'center',
    dataIndex: "loginType"
  },
  {
    title: "移动系统版本号",
    align:'center',
    dataIndex: "clientName"
  },
  {
    title: "登录时间",
    align:'center',
    dataIndex: "loginTime"
  }
];
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    loginHistoryList: { // 收货地址
				type: Array,
				default: () => [],
    },
    refreshList: {
      type: Function
    }
  },
  // 数据对象
  data () {
    return {
      loginHistoryColumns,

      // 登录信息筛选条件
      findtime: [], // 登陆时间区间
      loginType: undefined, // 登陆来源
    }
  },
  // 事件处理器
  methods: {
    refresh() {
      this.$emit('refreshList',this.$route.query.userId,this.loginType,this.findtime[0],this.findtime[1])
    }
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.Up_Down_inner{
  margin-bottom: 10px;
  .all_left_name{
    font-size: 18px;
  }
}
.searchBox{
  margin-top: 20px;
  .ant-select{
    margin-right: 20px;
  }
}
.all_main_wrap{
  padding: 0 16px;
}
.table-box{
  width: 800px;
}
.delItem{
  font-size: 12px;
  color: #2B6ED4;
  line-height: 14px;
  cursor: pointer;
}
</style>
