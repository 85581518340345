<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">用户查询</span>
        <!-- 面包屑 -->
        <div class="crumbs">
          <router-link class="crumbs_item" tag="a" to="/admin/Search/UserQuery"
            >用户查询 /
          </router-link>
          <span class="crumbs_item crumbs_last">详情</span>
        </div>
      </div>
    </div>
    <!-- 详情 -->
    <div class="detail-box">
      <a-tabs default-active-key="1" >
        <a-tab-pane key="1" tab="身份信息">
          <Information-List :idList="idList" />
          <ServiceTeacher :teacherList="teacherList" :notesList="notesList"/>
          <div>支付记录</div>
          <!-- 收货地址和发票抬头 -->
          <PayRecord-List :payList="payList" />
          <InvoiceAndaddress-List :addressList="addressList" :invoiceList="invoiceList" />
          <LoginHistory @refreshList="(id,source,start,end)=>{getloginHistoryList(id,source,start,end)}" :loginHistoryList="loginHistoryList" />
        </a-tab-pane>
        <a-tab-pane key="2" tab="学习记录">
          <Record-List
            @setSignUpColumns="getSignUpColumns"
            :studyList="studyList"
            :signList="signList"
            :examList="examList"
            :learnList="learnList"
            :learnProveList="learnProveList"
          />
        </a-tab-pane>
        <!-- <a-tab-pane key="3" tab="支付记录"></a-tab-pane> -->
      </a-tabs>
    </div>
  </div>
</template>

<script>
import InformationList from "./InformationList.vue"; // 身份信息
import ServiceTeacher from "./ServiceTeacher.vue"; // 服务老师
import RecordList from "./RecordList.vue"; // 学习记录
import PayRecordList from "./PayRecordList.vue"; // 支付记录
import InvoiceAndaddressList from "./invoiceAndaddress.vue"; // 收货地址发票抬头
import LoginHistory from "./loginHistory.vue"; // 收货地址发票抬头
export default {
  // 可用组件的哈希表
  components: { InformationList, ServiceTeacher,RecordList, PayRecordList, InvoiceAndaddressList, LoginHistory },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      key:'',
      idList: {}, // 身份信息
      studyList: [], // 课程学习
      teacherList:[],//服务老师下拉框
      notesList:[],//服务老师备注记录
      signList: [], //考试报名
      scoreList: [], //
      SignUpColumns: [], // 考试报名表头
      examList: [], // 模拟考试
      learnList: [], // 证书
      learnProveList: [], // 学习证明
      payList: [], // 支付记录
      addressList: [], // 收货地址
      invoiceList: [], // 发票抬头
      loginHistoryList: [], // 登录日志
    };
  },
  // 事件处理器
  methods: {
    getList(e) {
      this.$ajax({
        url: "/hxclass-management/user/info/details",
        params: {
          userId: e,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.idList = res.data;
        }
      });
    },
    getStudyList(e) {
      this.$ajax({
        url: "/hxclass-management/course/manage/" + e,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.studyList = res.data;
        }
      });
    },
    getSignUpColumns(e) {
      this.SignUpColumns = e;
      // console.log(this.SignUpColumns)
    },
    getSignList(e) {
      this.SignUpColumns = [];
      this.$ajax({
        url: "/hxclass-management/exam/signup/compre/signup/list",
        params: {
          userId: e,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          let columns = [];
          res.data.forEach((element) => {
            if (element.scorerules) {
              // JSON.parse(element.scorerules).forEach(subItem => {
              //   columns.push(subItem.name)
              // });
              element.scorerules.forEach((subItem) => {
                columns.push(subItem.name);
              });
            }
          });
          // console.log(Array.from(new Set(columns)))

          Array.from(new Set(columns)).forEach((element, index) => {
            let obj = {
              title: element,
              align: "center",
              dataIndex: "score" + (index + 1),
            };
            this.SignUpColumns.splice(this.SignUpColumns.length - 2, 0, obj);
          });

          // console.log(this.SignUpColumns)

          Array.from(new Set(columns)).forEach((n, i) => {
            res.data.forEach((item) => {
              if (item.scorerules) {
                // JSON.parse(item.scorerules).forEach((subItem) => {
                //   if(subItem.name == n){
                //       item['score'+ (i + 1)] = subItem.score
                //   }
                // });
                item.scorerules.forEach((subItem) => {
                  if (subItem.name == n) {
                    item["score" + (i + 1)] = subItem.score;
                  }
                });
              }
            });
          });

          this.signList = res.data;
          // console.log(this.signList);
        }
      });
    },
    getExamList(e) {
      this.$ajax({
        url: "/hxclass-management/exam/mock/config/compre-mork/list",
        params: {
          userId: e,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.examList = res.data;
        }
      });
    },
    getLearnList(e) {
      this.$ajax({
        url: "/hxclass-management/user-certif/user-query/detail",
        params: {
          userId: e,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.learnList = res.data;
        }
      });
    },
    getLearnProveList(e) {
      this.$ajax({
        url: "/hxclass-management/user-certif/selByUserId",
        params: {
          userId: e,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.learnProveList = res.data;
        }
      });
    },
    getPayList(e) {
      this.$ajax({
        url: "/hxclass-management/order/user-query/detail",
        params: {
          userId: e,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.payList = res.data;
        }
      });
    },
    // 查询收货地址
    getAddressList(e) {
      this.$ajax({
        url: "/hxclass-management/ship/address/getUseList",
        params: {
          userId: e,
          // pageNum:1,
          // pageSize:100,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.addressList = res.data;
        }
      });
    },
    // 查询发票抬头
    getInvoiceList(e) {
      this.$ajax({
        url: "/hxclass-management/invoice/header/useList",
        params: {
          userId: e,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.invoiceList = res.data;
        }
      });
    },
    // 查询用户登录日志
    getloginHistoryList(e,source,start,end) {
      this.$ajax({
        url: "/hxclass-management/login/log/select/userLogByUserId",
        params: {
          userId: e,
          end: end || '',
          loginType: source || '',
          start: start || ''
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.loginHistoryList = res.data;
        }
      });
    },
    // 查询服务老师下拉框
    getserviceTeacherList() {
      this.$ajax({
        url: "/hxclass-management/user/info/selectComboBoxData",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.teacherList = res.data;
        }
      });
    },

  },
  // 生命周期-实例创建完成后调用
  created() {
    this.id = this.$route.query.userId;

    this.getList(this.id); // 身份信息
    this.getserviceTeacherList();//服务老师下拉框
    this.getPayList(this.id); // 支付记录
    this.getAddressList(this.id); //收货地址
    this.getInvoiceList(this.id); //发票抬头

    this.getStudyList(this.id);  // 学习记录
    this.getSignList(this.id); // 考试报名
    this.getExamList(this.id); // 模拟考试
    this.getLearnList(this.id);  //证书列表
    this.getLearnProveList(this.id);  //学习证明
    this.getloginHistoryList(this.id); // 登录日志
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.detail-box {
  /deep/.ant-tabs-bar {
    border: none;
  }
  /deep/ .ant-tabs-tab {
    font-size: 18px;
  }
}
</style>
