<template>
  <div class="all_main_wrap">
    <div class="all_content_box">
      <!-- 个人 -->
      <div class="Up_Down_inner">
          <a-table
            class="table-template"
            :rowKey="item=>item.orderId"
            :columns="PersonalColumns"
            :data-source="payList"
            :bordered="true"
            :pagination="false"
            >
            <template slot="index" slot-scope="item, row, i">
              <div style="text-align: center;">
                {{ i + 1 }}
              </div>
            </template>
            <template slot="paymentTime" slot-scope="text">
             {{ text?createtime(text):'-' }}
            </template>
            <template slot="operation" slot-scope="text, record">
              <div class="btn_router_link">
                <a v-if="record.isInvoice" @click="invoiceMessage(record)"
                  >发票信息</a
                >
                <span v-if="record.isInvoice">|</span>
                <a @click="godetail(record.orderId, record.userType)">详情</a>
              </div>
            </template>
            <template slot="refundStatus" slot-scope="text">
             {{ text == 2?'部分退款':text == 3?'全部退款':'未退款' }}
            </template>
        </a-table>
      </div>
    </div>
    <!-- 学员名单 -->
    <!-- <a-modal :footer="null" align="center"  v-model="visible" title="学员名单" width="700px">
      <a-table
          class="table-template"
          :rowKey="item=>item.id"
          :columns="StudentColumns"
          :data-source="tableData"
          >
          <template slot="index" slot-scope="item, row, i">
            <div style="text-align: center;">
              {{ i + 1 }}
            </div>
          </template>
      </a-table>
    </a-modal> -->

    <!-- 发票信息弹框 -->
    <a-modal
      class="invoceModel"
      title="发票信息"
      :width="813"
      :visible="invoiceVisible"
      @cancel="invoiceVisible = false"
      :footer="false"
    >
      <a-table
        class="table-template"
        :rowKey="(item, index) => index"
        :columns="columns3"
        :data-source="invoiceArray"
        :scroll="{ x: 800, y: 240 }"
        :pagination="false"
      >
        <template slot="operation" slot-scope="text, record">
          <a :href="record.pdf" target="_block" v-if="record.pdf">查看发票</a>
          <span v-else>--</span>
        </template>
        <template slot="status" slot-scope="text, record">
          {{
            text == 1
              ? record.isChange == 1
                ? "开票中"
                : "开票中"
              : text == 2
              ? record.isChange == 1
                ? "已开票"
                : "已开票"
              : text == 3
              ? "已作废"
              : ""
          }}
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
const PersonalColumns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "商品名称",
    align:'center',
    width:500,
    dataIndex: "productName"
  },
  {
    title: "订单编号",
    align:'center',
    dataIndex: "orderCode"
  },
  {
    title: "订单类型",
    align:'center',
    dataIndex: "orderType"
  },
  {
    title: "用户类型",
    align:'center',
    dataIndex: "userType"
  },
  {
    title: "支付时间",
    align:'center',
    dataIndex: "paymentTime",
    scopedSlots: { customRender: 'paymentTime' }
  },
  {
    title: "优惠券",
    align:'center',
    dataIndex: "couponName"
  },
  {
    title: "实付金额",
    align:'center',
    dataIndex: "payPrice"
  },
  {
    title: "活动来源",
    align:'center',
    dataIndex: "source"
  },
  {
    title: "退款状态",
    align:'center',
    dataIndex: "refundStatus",
    scopedSlots: { customRender: 'refundStatus' }
  },
  {
    title: "退款数量",
    align:'center',
    dataIndex: "refundCount"
  },
  {
    title: "退款金额",
    align:'center',
    dataIndex: "refundPrice"
  },
  {
    title: "操作",
    align:'center',
    width: "150px",
    scopedSlots: { customRender: "operation" }
  }
];

const mashanColumns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "商品名称",
    align:'center',
    dataIndex: "code1"
  },
  {
    title: "订单编号",
    align:'center',
    dataIndex: "code2"
  },
  {
    title: "订单类型",
    align:'center',
    dataIndex: "code3"
  },
  {
    title: "用户类型",
    align:'center',
    dataIndex: "code4"
  },
  {
    title: "支付时间",
    align:'center',
    dataIndex: "code5"
  },
  {
    title: "优惠券",
    align:'center',
    dataIndex: "code6"
  },
  {
    title: "实付金额",
    align:'center',
    dataIndex: "code7"
  },
  {
    title: "活动来源",
    align:'center',
    dataIndex: "code8"
  },
  // {
  //   title: "操作",
  //   align:'center',
  //   width: "150px",
  //   scopedSlots: { customRender: "operation" }
  // }
];
const StudentColumns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "用户账号",
    align:'center',
    dataIndex: "code1"
  },
  {
    title: "姓名",
    align:'center',
    dataIndex: "code2"
  },
  {
    title: "课程期数",
    align:'center',
    dataIndex: "code3"
  },
  {
    title: "课程有效期",
    align:'center',
    dataIndex: "code4"
  },
  {
    title: "学习进度",
    align:'center',
    dataIndex: "code5"
  }
];
const columns3 = [
  {
    title: "订单编号",
    align: "center",
    dataIndex: "subOrderCode",
    width: "200px",
  },
  {
    title: "发票状态",
    align: "center",
    dataIndex: "status",
    width: "150px",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "发票号",
    align: "center",
    dataIndex: "fphm",
    width: "100px",
  },
  {
    title: "发票金额",
    align: "center",
    dataIndex: "price",
    width: "100px",
  },
  {
    title: "商品信息",
    align: "center",
    dataIndex: "productInfo",
    width: "300px",
  },
  {
    title: "商品类型",
    align: "center",
    dataIndex: "productTypeTxt",
    width: "100px",
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: "100px",
    scopedSlots: { customRender: "operation" },
  },
];
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    payList: {
				type: Array,
				default: () => [],
		},
  },
  // 数据对象
  data () {
    return {
      invoiceVisible: false, // 发票信息弹框
      PersonalColumns, // 个人支付记录
      columns3, // 发票信息表头
      mashanColumns, // 机构支付记录    一期不 上，页面已画出
      StudentColumns, // 学员名单    二期不上，页面已画出
      visible:false, // 学员名单弹窗
      invoiceArray: [], // 发票信息数据
      tableData:[]
    }
  },
  // 事件处理器
  methods: {
    // 跳转
    godetail(e, r) {
      if (r == 2) {
        this.$router.push("/admin/OrderConfig/orgOrderDetail?orderId=" + e);
      } else {
        this.$router.push("/admin/OrderConfig/OrderDetail?orderId=" + e);
      }
    },
    // 发票信息
    invoiceMessage(e) {
      this.$ajax({
        url: "/hxclass-management/order/list/invoice",
        method: "get",
        params: {
          orderId: e.orderId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.invoiceArray = res.data;
          this.invoiceVisible = true;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    onPaperDetail(){
      this.$router.push({ path: "/admin/Search/PaperDetail" })
    },
    repair0(m) {
      return m < 10 ? '0' + m : m
    },
    createtime(times) {
      var time = new Date(times);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return y + '-' + this.repair0(m) + '-' + this.repair0(d) + ' ' + this.repair0(h) + ':' + this.repair0(mm) +
        ':' + this.repair0(s);
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    // 生成假数据
    // for(let i=1; i<7; i++){
    //   this.tableData.push({
    //     id:i,code1:'数据' + i, code2:'数据' + i, code3:'数据' + i, code4:'数据' + i, code5:'数据' + i, code6:'数据' + i, code7:'数据' + i, code8:'数据' + i, code9:'数据' + i, code10:'数据' + i })
    // }
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.Up_Down_inner{
  margin-bottom: 10px;
  .all_left_name{
    font-size: 18px;
  }
}
.all_main_wrap{
  padding: 0 16px;
}
.table-box{
  width: 800px;
}
.delItem{
  font-size: 12px;
  color: #2B6ED4;
  line-height: 14px;
  cursor: pointer;
}
</style>
