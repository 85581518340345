<template>
  <div class="all_main_wrap">
    <div class="all_content_box">
      <!-- 课程学习 -->
      <div class="Up_Down_inner">
        <span class="all_left_name">课程学习</span>
        <div>
          <a-table
            class="table-template"
            :rowKey="item=>item.userCourseId"
            :columns="StudyColumns"
            :data-source="studyList"
            :bordered="true"
            :pagination="false"
            >
            <template slot="index" slot-scope="item, row, i">
              <div style="text-align: center;">
                {{ i + 1 }}
              </div>
            </template>
            <template slot="orderCode" slot-scope="item">
                {{item?item:'-' }}
            </template>
            <template slot="studyProgress" slot-scope="item">
                {{item * 100 + "%"}}
            </template>
            <template slot="buyMethod" slot-scope="item">
                {{item==1?'线上购买':item==2?'线下补录':item==3?'加入学习':item==4?'机构分配':'-' }}
            </template>
            <template slot="orgName" slot-scope="item">
                {{item ? item : '-'}}
            </template>
            <template slot="operation" slot-scope="item,text">
              <div class="btn_router_link" v-if="text.delayCount>0">
                <span class="a" @click="getCourseselect(text)">延期记录</span>
              </div>
              <div v-else>-</div>
            </template>
        </a-table>
        </div>
      </div>
      <!-- 考试报名 -->
      <div class="Up_Down_inner">
        <span class="all_left_name">考试报名</span>
        <div>
          <a-table
            class="table-template"
            :rowKey="(item,index) => index + '-'"
            :columns="SignUpColumns"
            :data-source="signList"
            :bordered="true"
            :pagination="false"
            >
            <template slot="index" slot-scope="item, row, i">
                {{ i + 1 }}
            </template>
            <template slot="examNum" slot-scope="item">
                {{item == null?0:item}}
            </template>
        </a-table>
        </div>
      </div>
      <!-- 模拟考试 -->
      <div class="Up_Down_inner">
        <span class="all_left_name">模拟考试</span>
        <div>
          <a-table
            class="table-template"
            :rowKey="item=>item.configId"
            :columns="ExamColumns"
            :data-source="examList"
            :bordered="true"
            :pagination="false"
            >
            <template slot="index" slot-scope="item, row, i">
                {{ i + 1 }}
            </template>
            <template slot="operation" slot-scope="item">
              <div class="btn_router_link">
                <span class="a" @click="onAnswer(item,true)">答题详情</span>
              </div>
          </template>
        </a-table>
        </div>
      </div>
      <!-- 证书 -->
      <div class="Up_Down_inner">
        <span class="all_left_name">证书</span>
        <div>
          <a-table
            class="table-template"
            :rowKey="item=>item.certifId"
            :columns="LearnColumns"
            :data-source="learnList"
            :bordered="true"
            :pagination="false"
            >
            <template slot="index" slot-scope="item, row, i">
              <div style="text-align: center;">
                {{ i + 1 }}
              </div>
            </template>
            <template slot="code" slot-scope="text">
              {{ text?text:'-' }}
            </template>
            <template slot="getcertifTime" slot-scope="text">
              {{ text?createtime(text):'-' }}
            </template>
          <template slot="operation" slot-scope="item">
            <div class="btn_router_link">
              <span class="a" @click="showModal(item.picture)">查看图片</span>
              <span>|</span>
              <span class="a" @click="downImg(item.picture)">下载证书</span>
            </div>
          </template>
        </a-table>
        </div>
      </div>
      <!-- 学习证明 -->
      <div class="Up_Down_inner">
        <span class="all_left_name">学习证明</span>
        <div>
          <a-table
            class="table-template"
            :rowKey="item=>item.studyCertifId"
            :columns="LearnProveColumns"
            :data-source="learnProveList"
            :bordered="true"
            :pagination="false"
            >
            <template slot="index" slot-scope="item, row, i">
              <div style="text-align: center;">
                {{ i + 1 }}
              </div>
            </template>
            <template slot="code" slot-scope="text">
              {{ text?text:'-' }}
            </template>
            <template slot="getcertifTime" slot-scope="text">
              {{ text?createtime(text):'-' }}
            </template>
          <template slot="operation" slot-scope="item">
            <div class="btn_router_link">
              <span class="a" @click="showModal(item.url)">查看图片</span>
              <span>|</span>
              <span class="a" @click="downImg(item.url)">下载证书</span>
            </div>
          </template>
        </a-table>
        </div>
      </div>
    </div>
    <!-- 答题详情 -->
    <a-modal :footer="null" align="center"  v-model="AnswerVisible" title="答题详情" width="700px">
      <a-table
          class="table-template"
          :rowKey="item=>item.userpPaperId"
          :columns="AnswerColumns"
          :data-source="answerList"
          @change="onPage"
          :pagination="{
            total:total,
            current:current,  
            defaultPageSize:size, 
           } "
          >
          <template slot="index" slot-scope="item, row, i">
              {{ (current - 1) * 10 + i + 1 }}
          </template>
        <template slot="operation" slot-scope="item">
          <div class="btn_router_link">
            <span class="a" @click="onPaperDetail(item.userpPaperId)">试卷</span>
          </div>
        </template>
      </a-table>
    </a-modal>
    <!-- 查看证书图片 -->
    <a-modal v-model="visible" title="证书图片">
      <div class="viscimg">
        <img :src="viscimg" alt="" />
      </div>
      <template slot="footer">
        <a-button type="primary" @click="handleOk">确认</a-button>
      </template>
    </a-modal>
    <!-- 延期记录 -->
    <a-modal :footer="null" align="center"  v-model="delayVisible" title="延期记录" width="1000px">
      <a-table
          class="table-template"
          :rowKey="item=>item.userpPaperId"
          :columns="delayColumns"
          :data-source="delayList"
          @change="onPage"
          :pagination="false"
          >
          <template slot="index" slot-scope="item, row, i">
              {{ (current - 1) * 10 + i + 1 }}
          </template>
          <template slot="oldTime" slot-scope="item, row">
              {{ row.oldStartTime }} / {{ row.oldEndTime }}
          </template>
          <template slot="newTime" slot-scope="item, row">
              {{ row.startTime }} / {{ row.endTime }}
          </template>
        <template slot="operation" slot-scope="item">
          <div class="btn_router_link">
            <span class="a" @click="onPaperDetail(item.userpPaperId)">试卷</span>
          </div>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
const StudyColumns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    scopedSlots: { customRender: 'index' }
  },
  {
    title: "课程名称",
    align:'center',
    dataIndex: "courseName"
  },
  {
    title: "课程期数",
    align:'center',
    dataIndex: "className"
  },
  {
    title: "有效期",
    align:'center',
    dataIndex: "validTime"
  },
  {
    title: "学习进度",
    width: "100px",
    align:'center',
    dataIndex: "studyProgress",
    scopedSlots: { customRender: 'studyProgress' }
  },
  {
    title: "延期次数",
    align:'center',
    dataIndex: "delayCount"
  },
  {
    title: "实付金额",
    align:'center',
    dataIndex: "payPrice"
  },
  {
    title: "支付时间",
    align:'center',
    dataIndex: "payTime"
  },
  {
    title: "订单编号",
    align:'center',
    dataIndex: "orderCode",
    scopedSlots: { customRender: 'orderCode' }
  },
  {
    title: "购买方式",
    align:'center',
    dataIndex: "buyMethod",
    scopedSlots: { customRender: 'buyMethod' }
  },
  {
    title: "机构名称",
    align:'center',
    dataIndex: "orgName",
    scopedSlots: { customRender: 'orgName' }
  },
  {
    title: "操作",
    align:'center',
    width: "170px",
    scopedSlots: { customRender: "operation" }
  }
];

const ExamColumns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "模考名称",
    align:'center',
    dataIndex: "name"
  },
  {
    title: "参与次数",
    align:'center',
    dataIndex: "examNum"
  },
  {
    title: "支付金额",
    align:'center',
    dataIndex: "examPrice"
  },
  {
    title: "操作",
    align:'center',
    width: "170px",
    scopedSlots: { customRender: "operation" }

  }
];
const LearnColumns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "证书名称",
    align:'center',
    dataIndex: "name"
  },
  {
    title: "证书编号",
    align:'center',
    dataIndex: "code",
    scopedSlots: { customRender: "code" },
  },
  {
    title: "获证时间",
    align:'center',
    dataIndex: "getcertifTime",
    scopedSlots: { customRender: "getcertifTime" },
  },
  {
    title: "证书类型",
    align:'center',
    dataIndex: "typeTxt"
  },
  {
    title: "操作",
    align:'center',
    width: "180px",
    scopedSlots: { customRender: "operation" }

  }
];
const LearnProveColumns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "证书名称",
    align:'center',
    dataIndex: "certifName"
  },
  {
    title: "证书编号",
    align:'center',
    dataIndex: "certifCode",
    scopedSlots: { customRender: "certifCode" },
  },
  {
    title: "获证时间",
    align:'center',
    dataIndex: "gainTime",
    scopedSlots: { customRender: "gainTime" },
  },
  {
    title: "操作",
    align:'center',
    width: "180px",
    scopedSlots: { customRender: "operation" }

  }
];
const AnswerColumns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "答题时间",
    align:'center',
    dataIndex: "examDate"
  },
  {
    title: "成绩",
    align:'center',
    dataIndex: "examScore"
  },
  {
    title: "操作",
    align:'center',
    width: "170px",
    scopedSlots: { customRender: "operation" }

  }
];
const delayColumns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "延期时间",
    align:'center',
    dataIndex: "createTime"
  },
  {
    title: "原有效期",
    align:'center',
    scopedSlots: { customRender: 'oldTime' }
  },
  {
    title: "现有效期",
    align:'center',
    scopedSlots: { customRender: 'newTime' }
  },
  {
    title: "延期次数",
    align:'center',
    dataIndex: "delayCount"
  }
];
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    studyList: {
				type: Array,
				default: () => [],
		},
    signList: {
				type: Array,
				default: () => [],
		},
    examList: {
				type: Array,
				default: () => [],
		},
    learnList: {
				type: Array,
				default: () => [],
		},
    learnProveList: {
				type: Array,
				default: () => [],
		},
  },
  // 数据对象
  data () {
    return {
      StudyColumns, // 课程学习
      // SignUpColumns, // 考试报名
      ExamColumns, // 模拟考试
      LearnColumns, // 证书
      LearnProveColumns, // 学习记录
      AnswerColumns, // 答题详情
      delayColumns, // 延期记录
      AnswerVisible:false,
      delayVisible: false,
      delayList: [], // 课程延期记录
      answerList: [], // 答题详情
      visible: false,
      viscimg: "",
      current: 1,
      size: 10,
      total: 0,
      SignUpColumns: [
        {
          title: "序号",
          width: "80px",
          align:'center',
          scopedSlots: { customRender: 'index' }

        },
        {
          title: "考试名称",
          align:'center',
          dataIndex: "examname"
        },
        {
          title: "补考次数",
          align:'center',
          dataIndex: "examNum",
          scopedSlots: { customRender: 'examNum' }
        },
        {
          title: "考试日期",
          align:'center',
          dataIndex: "examdate"
        },
        {
          title: "考试场次",
          align:'center',
          dataIndex: "examdatetime"
        },
        {
          title: "报名状态",
          align:'center',
          dataIndex: "auditstatus"
        },
        {
          title: "考试状态",
          align:'center',
          dataIndex: "examstatus"
        },
        // {
        //   title: "考试成绩",
        //   align:'center',
        //   dataIndex: "examscore"
        // },
        {
          title: "最终成绩",
          align:'center',
          dataIndex: "totalscore"
        },
      ],
    }
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e){
      this.current = e.current;
      this.size = e.pageSize;
      this.onAnswer(this.answerDetail);
    },
    repair0(m) {
      return m < 10 ? '0' + m : m
    },
    createtime(times) {
      var time = new Date(times);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return y + '-' + this.repair0(m) + '-' + this.repair0(d) + ' ' + this.repair0(h) + ':' + this.repair0(mm) +
        ':' + this.repair0(s);
    },
    onPaperDetail(id){
      this.$router.push({ path: "/admin/Search/PaperDetail?userpPaperId=" + id })
    },
    onAnswer(item,flag){
      if(flag){
        this.current = 1;
      }
      this.answerDetail = item;
      this.AnswerVisible=true;
      this.$ajax({
        url: "/hxclass-management/exam/mock/config/user/list",
        params: {
          userId: item.userid,
          configId: item.configId,
          current: this.current,
          size: this.size,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.answerList = res.data.records;
        }
      });
    },
    // 查看图片
    showModal(e) {
      this.viscimg = e;
      this.visible = true;
    },
    handleOk(e) {
      // console.log(e);
      this.visible = false;
    },

    downImg(href){
      var fileName =  new Date().getTime() + ".png";
      this.downloadIamge(href, fileName);
    },
    downloadIamge(imgsrc, name) {
      //下载图片地址和图片名
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function() {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据'
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window
        }); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },
    // 获取延期记录列表
    getCourseselect(e) {
      this.delayList = [];
      this.$ajax({
        url: '/hxclass-management/course/selectByUserId/courseId',
        params: {
          courseId: e.courseId,
          userId: this.$route.query.userId
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.delayVisible = true;
          this.delayList = res.data;
        }else {
          this.$message.error(res.message)
        }
      });
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    // this.$router.go(0)
    this.$emit('setSignUpColumns',this.SignUpColumns)
  },
  // 生命周期-实例挂载后调用
  mounted () { 
    
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.Up_Down_inner{
  margin-bottom: 10px;
  .all_left_name{
    
    font-size: 18px;
  }
}
.all_main_wrap{
  padding: 0 16px;
}
.table-box{
  width: 800px;
}
.viscimg {
  width: 100%;
  text-align: center;
  img {
    width: 100%;
    margin: 0 auto;
  }
}
.a{
  cursor: pointer;
}
</style>
